import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 2,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Wrapped Kava',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
    tokenSymbol: 'WKAVA',
    earn: 'Kid NFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
    quoteTokenSymbol: QuoteToken.WKAWA,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    },
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'Ethereum',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    },
    tokenSymbol: 'ETH',
    earn: 'Grandpa NFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    },
  },
  {
    pid: 0,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USD Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
    tokenSymbol: 'USDC',
    earn: 'Man NFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    },
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: false,
    lpSymbol: 'USDT Coin',
    lpAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
    tokenSymbol: 'USDT',
    earn: 'Woman NFT',
    tokenAddresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: {
      97: '',
      56: '',
      137: '',
      2222: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    },
  },
]

export default farms
