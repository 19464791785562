import React, { useEffect, useState } from 'react'
import { Login, useModal, UserBlock } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import ProviderErrorModal from 'components/Menu/ProviderErrorModal'
import ChainErrorModal from 'components/Menu/ChainErrorModal'

export interface Props {
  account?: string
  login: Login
  logout: () => void
  error?: any
}

const Navbar = styled.nav`
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  max-width: 1536px;
  margin: auto;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`

const NavLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  width: 100%;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`

const Brand = styled.a`
  display: flex;
  align-items: center;
  color: #0b698d;
  font-weight: bold;
  text-transform: uppercase;
  img {
    max-height: 100px;
    max-width: 100%;
    margin-right: 6px;
  }
  span {
    color: #5cbb4f;
    display: block;
  }
`

const Nav: React.FC<Props> = ({ account, login, logout, error }) => {
  const [isInvalidBrowser, setInvalidBrowser] = useState(false)
  const [unsupportedChain, setUnsupportedChain] = useState(false)

  const [showProviderErrorModal] = useModal(<ProviderErrorModal />)

  const [showUnsupportedChainModal] = useModal(<ChainErrorModal />)

  useEffect(() => {
    if (!account && error) {
      if (error.name === 't' && !isInvalidBrowser) {
        showProviderErrorModal()
        setInvalidBrowser(true)
      }
      if (error.name === 'ChainUnsupportedError' && !unsupportedChain) {
        showUnsupportedChainModal()
        setUnsupportedChain(true)
      }
    } else {
      setInvalidBrowser(false)
      setUnsupportedChain(false)
    }
  }, [account, error, isInvalidBrowser, unsupportedChain, showProviderErrorModal, showUnsupportedChainModal])

  return (
    <Navbar>
      <Wrapper>
        <NavLeft>
          <Brand href="/">
            <img src="/Logo.png" alt="Rising Unded" />
          </Brand>
        </NavLeft>
        <UserBlock account={account} login={login} logout={logout} chainName="KAVA" chainURL="explorer.kava.io" />
      </Wrapper>
    </Navbar>
  )
}

export default Nav
