import React from 'react'
import styled from 'styled-components'
import Page from './layout/Page'
import BannerBG from '../assets/bg1.png'
import Stroke from '../assets/stroke.png'

const BannweWrapper = styled(Page)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  position: relative;
  max-width: 100%;
  height: 750px;
  margin-bottom: 60px;
  padding-top: 60px;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${BannerBG});
    background-position: bottom center;
    top: 0;
    z-index: -1;
  }
`

const BannerTitleWrapper = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    background-image: url(${Stroke});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 600px;
    height: 250px;
    left: -50px;
    top: -30px;
  }
`

const BannerTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  position: relative;
  z-index: 1;
  margin: 40px 0;
  line-height: 1;
  text-align: center;
`

const BannerHeader = () => {
  return (
    <BannweWrapper>
      <BannerTitleWrapper>
        <BannerTitle>
          Unleash your inner warrior <br /> & <br /> conquer the zombie horde
        </BannerTitle>
      </BannerTitleWrapper>
    </BannweWrapper>
  )
}

export default BannerHeader
